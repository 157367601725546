<template>
  <UiSidePanelForm
    :model-value="modelValue"
    :title="!isSynced ? 'Connect google' : activityToEdit ? 'Edit activity' : 'Create activity'"
    :primary-button-text="!isSynced ? '' : 'Save'"
    :secondary-button-text="!isSynced ? 'Skip' : 'Cancel'"
    :disabled="createForm?.loading"
    :size="isSynced ? 'large' : 'small'"
    @update:model-value="!isSynced ? (isSynced = true) : emits('update:modelValue', false)"
    @confirm="createForm?.submit"
  >
    <div v-if="!isSynced" class="flex flex-col items-center justify-center gap-4 rounded-2xl bg-primary-05 p-6">
      <div class="text-center">
        In order to schedule activities please connect Google account to work faster and be able to add video call.
      </div>
      <UiButtonBase id="connect_google" @click="useGoogleSync()">
        <UiIcon name="google" />
        Connect Google
      </UiButtonBase>
    </div>
    <ActivityCreateForm
      v-else
      ref="createForm"
      :activity-to-edit="activityToEdit"
      :lead="lead"
      :initial-date="initialDate"
      :for-another-user="forAnotherUser"
      :activity-type="activityType"
      @edited="emits('edited', $event), (modelValue = false)"
      @created="emits('created', $event), (modelValue = false)"
      @update-event-on-calendar="updateEventOnCalendar"
      @update-title-on-calendar="updateTitleOnCalendar"
    />
    <template v-if="isSynced" #sidePanel>
      <ActivityCreateDayCalendar
        ref="dayCalendar"
        :initial-date="activityToEdit ? activityToEdit.start : initialDate"
        :local-events="[createForm?.activity]"
        :get-activities="getActivities"
        :user="createForm?.activity.user"
        class="overflow-y-hidden"
        @selected:date="createForm?.selectedDate"
      />
    </template>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import { addHours, startOfHour } from 'date-fns'
import debounce from 'lodash/debounce'
import type { Activity, Lead } from '~/types'

const emits = defineEmits(['update:modelValue', 'edited', 'created'])
const user = useUser()

const modelValue = defineModel<boolean>({ required: true })

const dayCalendar = ref()
const createForm = ref()

export type EditSidePanelProps = {
  activityToEdit?: Activity
  lead?: Lead
  synced?: boolean
  initialDate?: Date
  forAnotherUser?: boolean
  activityType?: string
  getActivities?: boolean
}

const props = withDefaults(defineProps<EditSidePanelProps>(), {
  modelValue: false,
  activityToEdit: undefined,
  lead: undefined,
  initialDate: () => startOfHour(addHours(new Date(), 1)),
  activityType: undefined,
  getActivities: true,
})

const isSynced = ref(user?.is_calendar_synced || props.synced)

const updateTitleOnCalendar = debounce(() => {
  dayCalendar.value.getEvents()
}, 500)

const updateEventOnCalendar = (date: Date) => {
  dayCalendar.value?.gotoDay(date)
  dayCalendar.value?.getEvents()
}

onMounted(() => {
  useMittListen('tour:SDRQueue:createActivity:sync', async () => {
    synced.value = true

    await nextTick()

    await new Promise((resolve) => setTimeout(resolve, 300))

    useMittEvent('tour:SDRQueue:createActivity:selectMeetingType')
  })
})

onUnmounted(() => {
  useMittRemove('tour:SDRQueue:createActivity:sync')
})
</script>

<style scoped></style>
